import { camelToTitleCase } from "@/services/stringUtility"
import store from "@/store/store"

function enumAsSelectList(enumDictionary) {
    // Convert an enum Ie a dict of numbers vs text description to an array suitable for
    // use in a select control ie an array of dicts with two key / value pairs value and text
    return Object.keys(enumDictionary).map(k => {
        return {
            value: k,
            text: addSpaceToPascalCase(k),
        };
    });
}

function reverseEnum(enumDictionary) {
    // Enums are implemented as a key value pair where the key is a human readable name and the
    // value is an integer.But sometimes we need to get the human readable value from the integer
    // to do this we reverse the enum and produce a dictionary where ints are the keys and strings
    // are the values
    return Object.keys(enumDictionary).reduce((result, k) => {
        const v = enumDictionary[k];
        result[v] = addSpaceToPascalCase(k);
        return result;
    }, {});
}

function reverseEnumNoSpaces(enumDictionary) {
    // Enums are implemented as a key value pair where the key is a human readable name and the
    // value is an integer.But sometimes we need to get the human readable value from the integer
    // to do this we reverse the enum and produce a dictionary where ints are the keys and strings
    // are the values
    return Object.keys(enumDictionary).reduce((result, k) => {
        const v = enumDictionary[k];
        result[v] = k;
        return result;
    }, {});
}

function enumWithSpaces(enumDictionary) {
    // add spaces in enum values
    return Object.keys(enumDictionary).map(k => {
        return camelToTitleCase(k).toLowerCase()
    });
}

function addSpaceToPascalCase(value) {
    return value.replace(/([A-Z])/g, " $1").trim();
}

function isEnum(type) {
    let lowerEnums = Object.keys(store._modules.root._children.schema.state.enums)
        .map(e => e.toLowerCase());
    let lowerType = type.toLowerCase();
    return lowerEnums.includes(lowerType);
}

export default {
    enumAsSelectList,
    reverseEnum,
    reverseEnumNoSpaces,
    addSpaceToPascalCase,
    enumWithSpaces,
    isEnum
};
