import axios from "axios";
import exporter from "@/export/exporter";
import searching from "@/services/searching"
import { debounce } from "@/services/debounce"
import { equal, and } from "@/services/filtering"

let tableMixin = {
    props: {
        filter: {
            type: Object,
            default: null,
        },
        customFilter: {
            type: Object,
            default: null,
        },
        tableSchema: {
            type: Object,
            default: null
        },
        showExpand: {
            type: Boolean,
            default: false,
        },
        refreshKey: {
            type: String,
            default: "",
        },
        hasEditPage: {
            type: Boolean,
            default: false,
        },
        isNotServerHandled: {
            type: Boolean,
            default: false,
        },
        isAddDisabled: {
            type: Boolean,
            default: false,
        },
        isEditDisabled: {
            type: Boolean,
            default: false,
        },
        showButtons: {
            type: Boolean,
            default: false,
        },
        hideDefaultFooter: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            isLoading: true,
            search: "",
            itemTotal: undefined,
            options: {},
            items: [],
            apiData: {},

            addDialog: false,
            noDataDialog: false,
            editedItem: {},
            formKey: 0,
            fileKey: 0,
            previousFilter: {},
            footerProps: {
                "items-per-page-options": [10, 15, 30, 50, 100] },
        };
    },

    mounted() {
        this.previousFilter = this.filter;
        this.addDialog = false;
        for (let i = 0; i < this.tableSchema.headers.length; i++) {
            this.tableSchema.headers[i].class = "grey lighten-3";
        }
    },

    methods: {
        async load() {
            try {
                this.isLoading = true;
                let filter = this.tableFilter;
                let data = await this.getData();

                if (JSON.stringify(filter) !== JSON.stringify(this.tableFilter)) {
                    return;
                }
                if (!this.isNotServerHandled) {
                    this.itemTotal = data.total;
                }
                this.setItems(data.items);
                await this.setApiData();
            } finally {
                this.isLoading = false;
            }
        },

        setItems(items) {
            this.items = items;
        },

        async getData() {
            let params = {};
            let schema = this.getSchema(this.tableSchema.claimName);

            this.setSortBy(params);
            this.setPagination(params);
            this.setSearchFilters(params, schema);
            this.setFilters(params, schema);

            let response = await axios.post(
                "/api/" + this.tableSchema.apiMethod + "/List",
                params
            );
            return response.data;
        },

        setSortBy(params) {
            if (this.isEmpty(this.tableSchema.sorting)) {
                return;
            }
            for (let i = 0; i < this.tableSchema.sorting.length; i++) {
                let sorting = this.tableSchema.sorting[i];
                if (!Object.prototype.hasOwnProperty.call(this.tableFilter, sorting)) {
                    params.sortBy = sorting.column;
                    params.direction = sorting.direction;
                    break;
                }
            }
        },

        setPagination(params) {
            if (this.isNotServerHandled) {
                return;
            }
            params.skip = this.options
                ? (this.options.page - 1) * this.options.itemsPerPage
                : null;
            params.take = this.options ? this.options.itemsPerPage : null;
            if (this.options.sortBy && this.options.sortBy.length > 0) {
                params.sortBy = this.options.sortBy[0];
                if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
                    params.direction = "Descending";
                }
            }
        },

        setFilters(params, schema) {
            let filters = [];
            for (let property in this.tableFilter) {
                if (Object.prototype.hasOwnProperty.call(this.tableFilter, property)) {
                    let field = schema.fields.filter(e => e.camelSingular === property);
                    if (this.isEmpty(field) || field.length !== 1) {
                        continue;
                    }
                    field = field[0];
                    let filter = equal(property, this.tableFilter[property], field.nullableType);
                    filters.push(filter);
                }
            }
            if (!this.isEmpty(this.customFilter)) {
                filters.push(this.customFilter)
            }
            if (filters.length > 0) {
                if (!this.isEmpty(params.filter)) {
                    filters.push(params.filter);
                }
                params.filter = and(filters);
            }
        },

        setSearchFilters(params, schema) {
            params.filter = searching.buildSearchFilter(schema.fields, this.search, 2);
        },

        async setApiData() {
            for (let h = 0; h < this.tableSchema.headers.length; h++) {
                let header = this.tableSchema.headers[h];
                if (this.isEmpty(header.apiEndpoint)) {
                    continue;
                }
                let ids = this.items
                    .filter(e => !this.isEmpty(e[header.value]))
                    .map(e => e[header.value]);
                if (ids.length === 0) {
                    continue;
                }
                let foreignKeyResponse = await axios.post(
                    "/api/" + header.apiEndpoint + "/ListFromIds",
                    ids
                );
                this.$set(this.apiData, header.table, foreignKeyResponse.data);
                let apiData = this.apiData;
                this.apiData = {};
                setTimeout(() => {
                    this.apiData = apiData;
                }, 1);
            }
        },

        customSort(items, index, isDesc) {
            if (index.length === 0) {
                return items;
            }
            let header = this.tableSchema.headers.filter(i => i.value === index[0])[0];
            let tableName = Object.prototype.hasOwnProperty.call(header, "table")
                ? header.table
                : null;
            if (tableName === null) {
                items.sort((a, b) => {
                    if (!isDesc[0]) {
                        return a[index[0]] < b[index[0]] ? -1 : 1;
                    } else {
                        return b[index[0]] < a[index[0]] ? -1 : 1;
                    }
                });
                return items;
            }
            let apiTable = this.apiData[tableName];
            items.sort((a, b) => {
                if (!isDesc[0]) {
                    return [a[index[0]]].localeCompare(
                        apiTable[b[index[0]]],
                        undefined,
                        { numeric: true }
                    );
                } else {
                    return apiTable[b[index[0]]].localeCompare(
                        apiTable[a[index[0]]],
                        undefined,
                        { numeric: true }
                    );
                }
            });
            return items;
        },

        download() {
            if (!this.computedItems || this.computedItems.length === 0) {
                this.noDataDialog = true;
                return;
            }
            let self = this;
            let items = this.computedItems.map(i => {
                let row = {};
                this.tableSchema.headers
                    .filter(e => e.text !== "")
                    .forEach(e => {
                        if (this.isEmpty(e.table)) {
                            row[e.text] = i[e.value];
                        } else {
                            row[e.text] = self.apiData[e.table][i[e.value]];
                        }
                    });
                return row;
            });

            exporter.downloadAsExcel({
                title: this.tableSchema.excelTitle,
                items,
            });
        },

        async downloadFile(item, header) {
            if (header.table !== "files") {
                return;
            }
            this.downloadUrl(
                "/api/Files/GetFile/" + item[header.value],
                this.apiData[header.table][item[header.value]]
            );
        },

        onInput() {
            this.addDialog = false;
            this.load();
        },

        downloadUrl(url, name) {
            fetch(url)
                .then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(() => alert("oh no!"));
        },

        getSlotName(value) {
            return "item." + value;
        },

        addItem() {
            this.formKey++;
            this.editedItem = null;
            this.addDialog = true;
        },

        editItem(item) {
            this.formKey++;
            this.editedItem = item;
            this.addDialog = true;
        },

        async goToEditPage(item) {
            if (this.hasEditPage && !this.showExpand && !this.addDialog) {
                this.$router.push({
                    name: this.tableSchema.editPageName,
                    params: {
                        id: item.id,
                    },
                    query: {
                        filter: this.tableFilter,
                    },
                });
            }
        },

        async goToTable(tableName, id) {
            this.$router.push({
                name: tableName,
                params: {},
                query: {
                    filter: { ...this.tableFilter, id },
                },
            });
        },

        onDelete(item) {
            let index = this.items.findIndex(i => i.id === item.id);
            if (index > -1) {
                this.items.splice(index, 1);
            }
            this.addDialog = false;
        },

        itemMatches(item, tokens) {
            for (let i = 0; i < tokens.length; i++) {
                let token = tokens[i];
                let isMatch = false;
                let self = this;
                this.tableSchema.headers
                    .filter(e => e.text !== "")
                    .forEach(e => {
                        if (
                            (!Object.prototype.hasOwnProperty.call(e, "table") &&
                                self.includes(item[e.value], token)) ||
                            (Object.prototype.hasOwnProperty.call(e, "table") &&
                                self.includes(self.apiData[e.table][item[e.value]], token))
                        ) {
                            isMatch = true;
                        }
                    });

                if (!isMatch) {
                    return false;
                }
            }
            return true;
        },

        includes(value, token) {
            if (!value) {
                return false;
            }
            return ("" + value).toLowerCase().includes(token);
        },
    },

    computed: {
        filteredHeaders() {
            if (this.isEmpty(this.tableFilter)) {
                return this.tableSchema.headers;
            }

            return this.tableSchema.headers.filter(
                h => !Object.prototype.hasOwnProperty.call(this.tableFilter, h.value)
            );
        },

        computedItems() {
            if (!this.isNotServerHandled) {
                return this.items;
            }
            let tokens = this.search.toLowerCase().trim().split(" ");
            if (tokens.length === 1 && tokens[0] === "") {
                return this.items;
            }
            return this.items.filter(i => this.itemMatches(i, tokens));
        },
        tableFilter() {
            return this.getFilter(this.filter);
        },
        getTableHeight() {
            if (this.$route.name === this.tableSchema.itemsField) {
                const viewHeight = Math.max(
                    document.documentElement.clientHeight || 0,
                    window.innerHeight || 0
                );
                return viewHeight - 360;
            } else {
                return undefined;
            }
        },
        getItemsPerPage() {
            if (this.$route.name === this.tableSchema.itemsField) {
                let itemsPerPage = Math.floor((this.getTableHeight - 50) / 49);
                return itemsPerPage;
            } else {
                return undefined;
            }
        },
    },
    watch: {
        filter: {
            handler() {
                if (JSON.stringify(this.previousFilter) !== JSON.stringify(this.filter)) {
                    this.load();
                    this.previousFilter = Object.assign({}, this.filter);
                }
            },
        },
        refreshKey: {
            handler() {
                this.load();
            },
        },
        options: {
            deep: true,
            handler() {
                this.load();
            },
        },
        search: debounce(function () {
            if (!this.isNotServerHandled) {
                return this.load();
            }
        }, 300)
    },
};

export default tableMixin;
