import store from "@/store/store"
import dateUtility from "@/services/dateUtility"
import schemaService from "@/services/schemaService"
import { and, or, equal, startsWith, greaterThanOrEqual, lessThan } from "@/services/filtering"
import enumService from "@/services/enumService"
import { isNullOrWhiteSpace, trim } from "@/services/stringUtility";
import { isEmpty } from "@/services/objectUtility"

function buildSearchFilter(fields, searchText, depth = 2) {
    if (isNullOrWhiteSpace(searchText)) {
        return;
    }
    let wholePhraseMatches = or(buildTokenFilter(fields, searchText.trim(), depth));
    let tokens = extractTokens(searchText);
    if (tokens.length === 1) {
        return wholePhraseMatches;
    }

    let filters = tokens.map(token => or(buildTokenFilter(fields, token, depth)));

    // Use "and" because each token must match.
    let eachTokenMatches = and(filters);

    return or([wholePhraseMatches, eachTokenMatches]);
}

// This splits the search text into tokens. By default each word is a token, unless we
// have double quotes which explicitly define the token boundary.
function extractTokens(searchText) {
    searchText = searchText.trim();
    // This regex finds any text enclosed within double quotes.
    let phraseRegex = /"[^"]*("|$)/g;

    let phrases = searchText
        .match(phraseRegex)
        ?.map(p => trim(p, "\"")) ?? [];

    // Remove the phrases from the search string.
    searchText = searchText.replace(phraseRegex, "");

    // Treat the remaining words as separate tokens.
    let words = searchText.split(" ");

    let tokens = phrases
        .concat(words)
        .map(token => token.trim());

    let uniqueTokens = [...new Set(tokens)];

    return uniqueTokens.filter(token => !isNullOrWhiteSpace(token));
}

function buildTokenFilter(fields, token, depth, prefix) {
    let filters = [];
    prefix = isEmpty(prefix) ? "" : prefix;
    let hasFoundDate = false;
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        let property = prefix + field.camelSingular;
        if (!isFieldValid(field.camelSingular, field, depth)) {
            continue;
        }
        filters = getFieldFilters(token, property, filters, field, depth, prefix, hasFoundDate);
    }
    return filters;
}

function isFieldValid(property, field, depth) {
    if (schemaService.labelPropertiesToIgnore.includes(property)
        || (!isEmpty(field.attributes)
            && field.attributes.some(e => e.$type === "IgnoreForLabel"))) {
        return false;
    }
    let type = field.type;
    let labelTypes = [
        "DateTimeOffset",
        "DateTime",
        "String",
        "Guid"
    ];
    let enums = store._modules.root._children.schema.state.enums;
    let enumPascalNames = Object.keys(enums)
        .map(e => e.charAt(0).toUpperCase() + e.slice(1));
    let tableTypes = [
        "Decimal",
        "Integer",
        ...enumPascalNames,
        ...labelTypes
    ];
    if (depth > 1) {
        return tableTypes.includes(type);
    }
    if (depth === 1) {
        return labelTypes.includes(type);
    }
    return labelTypes.includes(type) && type !== "Guid";
}

function getFieldFilters(token, property, filters, field, depth, prefix, hasFoundDate) {
    if (depth > 0) {
        filters = addForeignKeyFilters(token, property, filters, field, depth, prefix);
    }
    if (property.endsWith("Id")) {
        return filters;
    }
    let type = field.type;
    filters = addDateFilters(token, property, filters, type, depth, hasFoundDate);
    filters = addStringFilters(token, property, filters, type);
    if (depth === 2) {
        filters = addIntegerFilters(token, property, filters, type);
        filters = addDecimalFilters(token, property, filters, type);
        filters = addEnumFilters(token, property, filters, type);
    }
    return filters;
}

function addForeignKeyFilters(token, property, filters, field, depth, prefix) {
    if (!property.endsWith("Id")) {
        return filters;
    }
    let fields = schemaService.getSchema(field.foreignKeyPascalPlural).fields;
    let nextPrefix = prefix + field.foreignKeyCamelSingular + ".";
    let foreignKeyFilters = buildTokenFilter(fields, token, depth - 1, nextPrefix);
    filters = filters.concat(foreignKeyFilters);
    return filters;
}

function addDateFilters(token, property, filters, type, depth, hasFoundDate) {
    if (type !== "DateTimeOffset"
        || hasFoundDate
        || depth === 0
        || !dateUtility.isDate(token)) {
        return filters;
    }
    let from = dateUtility.parseDate(token);
    let to = from.plus({ days: 1 });
    let isoFrom = from.toISODate() + "T00:00:00" + dateUtility.getIsoOffset();
    let isoTo = to.toISODate() + "T00:00:00" + dateUtility.getIsoOffset();
    let dateFilter = and([
        greaterThanOrEqual(property, isoFrom, "DateTimeOffset"),
        lessThan(property, isoTo, "DateTimeOffset")
    ]);
    filters.push(dateFilter);
    if (depth !== 2) {
        hasFoundDate = true;
    }
    return filters;
}

function addIntegerFilters(token, property, filters, type) {
    if (type !== "Integer" || !Number.isInteger(token)) {
        return filters;
    }
    filters.push(equal(property, +token));
    return filters;
}

function addDecimalFilters(token, property, filters, type) {
    if (type !== "Decimal" || isNaN(+token)) {
        return filters;
    }
    filters.push(equal(property, +token, "decimal"));
    return filters;
}

function addEnumFilters(token, property, filters, type) {
    let enums = store._modules.root._children.schema.state.enums;
    let enumPascalNames = Object.keys(enums)
        .map(e => e.charAt(0).toUpperCase() + e.slice(1));
    if (!enumPascalNames.includes(type)) {
        return filters;
    }
    let camelType = type.charAt(0).toLowerCase() + type.slice(1);
    let fieldEnum = enums[camelType];
    let enumWithSpaces = enumService.enumWithSpaces(fieldEnum);
    let lowerSearch = token.toLowerCase();
    if (!enumWithSpaces.some(e => e.startsWith(lowerSearch))) {
        return filters;
    }
    let matchingValues = enumWithSpaces.filter(e => e.startsWith(lowerSearch));
    for (let j = 0; j < matchingValues.length; j++) {
        let index = enumWithSpaces.indexOf(matchingValues[j]);
        filters.push(equal(property, index, "byte"));
    }
    return filters;
}

function addStringFilters(token, property, filters, type) {
    if (type !== "String") {
        return filters;
    }
    filters.push(startsWith(property, token));
    return filters;
}

export default {
    buildSearchFilter
};
